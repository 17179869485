#canvas_container {
    width: 289.61084px;
  }


.MyContainer div {
    margin: 0 auto;
    width: 80%;
}

.centered {
  text-align: center;
}